import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.png";
import image3 from "../assets/t-image3.jpg";
import image4 from "../assets/t-image4.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Waves GYM and by choosing the right plan and program I already achieved my ideal body!",
    name: 'MATHEW HENDRICKSON',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: "It was really Great experience with Waves GYM but dut to my changing in loc I need to left But Will Join same GYM which is in my area. ",
    name: 'JOHN KEVIN',
    status: 'CUSTOMER'
  },
  {
    image : image3,
    review:" Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem ",
    name: 'Shone Nelly',
    status: "COACH"
  },
  {
    image : image4,
    review:" I like my trainer. I like the look of the studio. I like the people who use it. I like the mix of those people. ",
    name: 'FRANKLIN',
    status: "CUSTOMER"
  }
];
